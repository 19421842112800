.navbar {
  padding-top: 20px;
  padding-left: 10%;
  padding-right: 10%;
}
.navbar-brand {
  color: #000a2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px;
}

.navbar-light .navbar-nav .nav-link {
  color: #000a2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px;
  margin-left: 5px;
  margin-right: 5px;
}
.bg-light {
  background-color: #fafafa !important;
}

.nav-link:hover:before {
  visibility: visible;
  width: 100%;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

@media (min-width: 768px) {

  .nav-link {
    position: relative;
    text-decoration: none;
  }

  .nav-link:before {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #000a2e;
    visibility: hidden;
    transition: all 0.4s ease-in-out;
  }
}

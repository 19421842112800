.h-100-vh {
  height: 100vh;
}

.hero-header {
  padding: 0;
  font-size: 1.75rem;
  text-align: left;
}

.hero-subhead {
  margin-top: 40px;
  margin-bottom: 20px;
}

.hero-header::after {
  content: '';
  position: absolute;
  width: 0;
  height: 10px;
  background-color: #000a14;
  visibility: visible;
  width: 1em;
}

.hero-social {
  font-size: 1.5rem;
  text-align: left;
}

.social-link {
  font-size: 1.5rem;
  color: #000a14;
  margin-right: 30px;
}


@media (min-width: 768px) {
  .hero-subhead {
    margin-top: 10px;
  }
}

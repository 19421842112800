* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body,
html {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  background-color: #fafafa;
  color: #000a14;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: left;
  font-family: 'DM Serif Display', serif;
  font-weight: 400;
}

h1 {
  font-size: 5rem;
  line-height: 1.2;
  display: block;
}
h2 {
  padding-top: 25px;
  padding-bottom: 25px;
  font-size: 3rem;
  text-align: left;
}

h3 {
  font-size: 1.5rem;
}

p {
  font-size: 1.25em;
  line-height: 1.6;
}

footer {
  color: #000a2e;
}

.lead {
  font-size: 1.25em;
  line-height: 1.6;
  text-align: left;
}

.social {
  font-size: 1.5rem;
  margin-top: 10px;
  color: #000a14;
  margin-left: 10px;
  margin-right: 10px;
}


.fa-angle-up:before {
  font-size: 40px;
  color: #000a2e;
}

a:-webkit-any-link {
  color: #000a2e;
}

.up-angle {
  margin-top: 50px;
  margin-bottom: 10px;
}

.down-angle {
  display: none
}

.bounce-2 {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: bounce-2;
  animation-timing-function: ease;
}

@keyframes bounce-2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

.container {
  margin-top: 50px;
  padding-right: 10%;
  padding-left: 10%;
}

a.link-btn {
  color: #000a14;
  position: relative;
  text-decoration: none;
  font-size: 20px;
  padding: 10px 0;
  margin-right: 15px;
}

.link-btn:before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000a14;
  visibility: visible;
  width: 100%;
}

.section-content {
  padding-top: 40px;
}

.copyright {
  font-size: 1rem;
}

.bio {
  margin-top: 0;
}

.bio-links {
  margin-top: 10px;
  padding: 0;
}

/* for screeens with width 768px and up*/
@media (min-width: 768px) {
  .link-btn:hover:before {
    width: 0;
    visibility: hidden;
    transition: all 0.4s ease-in-out;
  }
  .fa-angle-down:before {
    font-size: 40px;
    color: #000a2e;
  }
  .down-angle {
    display: block;
    padding-bottom: 100px;
  }
}

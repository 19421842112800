.project-item {
  margin-bottom: 30px;
  margin-top: 30px;
  margin-left: 0px;
  margin-right: 0px;
}

.project-image {
  width: 70vw;
  max-width: 400px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
}

.project-title {
  margin-top: 20px;
}

.slick-prev:before, .slick-next:before {
  color: #000000;
}

@media (min-width: 1200px) {
  .project-image {
    margin-right: 100px;
  }
}

.expertise-lead {
  margin-top: 20px;
  margin-bottom: 30px;
}

.expertise-icon {
  font-size: 25px;
  margin-right: 20px;
  margin-bottom: 20px;
  align-self: flex-end;
}

.expertise-tiles {
  justify-content: center;
}

.front-content {
  position: absolute;
  bottom: 0;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.flip-card {
  border: 0;
  padding: 0;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 250px;
  height: 250px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.30);
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  padding: 20px;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  z-index: 8;
  background: #000000;
  color: white;
}

/* Style the back side */
.flip-card-back {
  text-align: left;
  background-color: #fafafa;
  color: black;
  transform: rotateY(180deg);
}

@media (min-width: 768px) {
  .expertise-tiles {
    justify-content: left;
  }
}
